import React from "react"
import { Router } from "@reach/router"
import {
  Survey,
  SecondSurvey,
  SubmitSuccess,
  PrivateRoute,
  Layout,
} from "../components"

const User = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/user/survey" component={Survey} />
      <PrivateRoute path="/user/submit-success" component={SubmitSuccess} />
      <PrivateRoute path="/user/second-survey" component={SecondSurvey} />
    </Router>
  </Layout>
)

export default User
